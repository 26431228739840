var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"d-flex flex-wrap align-items-center"},_vm._l((_vm.menuItems),function(item,index){return (
      !item.roles ||
      (_vm.currentUser && _vm.checkIfHaveRole(_vm.currentUser.rol, item.roles))
    )?_c('li',{key:index,staticClass:"px-6"},[(item.name !== 'manual' && item.name !== 'help-desk')?_c('div',[_c('b-link',{class:`px-12 py-4${
          _vm.path.split('/')[1] == item.header.toLowerCase() ? ' active' : ''
        }${
          _vm.path.split('/')[1] == 'components' &&
          item.header.toLowerCase() == 'user interface'
            ? ' active'
            : ''
        }`,attrs:{"to":item.navLink}},[_c('span',[_vm._v(_vm._s(item.header))]),(item.children.length)?_c('i',{staticClass:"ri-arrow-down-s-line"}):_vm._e()])],1):_c('div',[_c('b-link',{class:`px-12 py-4${
          _vm.path.split('/')[1] == item.header.toLowerCase() ? ' active' : ''
        }${
          _vm.path.split('/')[1] == 'components' &&
          item.header.toLowerCase() == 'user interface'
            ? ' active'
            : ''
        }`,on:{"click":() => { item.name === 'manual' ? _vm.downloadManual() : item.name === 'help-desk' ? _vm.openHelpDesk() : null}}},[_c('span',[_vm._v(_vm._s(item.header))]),(item.children.length)?_c('i',{staticClass:"ri-arrow-down-s-line"}):_vm._e()])],1),(item.children.length)?_c('ul',{staticClass:"dropdown-menu"},_vm._l((item.children),function(item,index){return _c('li',{key:index},[(item.children)?_c('b-link',{class:`dropdown-item${
            _vm.path.split('/')[2] == item.id ? ' active' : ''
          }`},[_c('span',[(item.icon)?_c('i',{class:`iconly-${item.icon}`}):_vm._e(),(item.iconSvg)?_c('div',{staticClass:"submenu-item-icon",domProps:{"innerHTML":_vm._s(item.iconSvg)}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.title))])]),_c('i',{staticClass:"dropdown-menu-arrow"})]):_c('b-link',{class:`${_vm.path == item.navLink ? 'active' : ''}`,style:(item.tag ? 'pointer-events: none;' : ''),attrs:{"to":item.navLink}},[(item.tag)?_c('span',{staticClass:"justify-content-between w-100"},[_c('div',{staticClass:"d-flex align-items-center"},[(item.icon)?_c('i',{class:`iconly-${item.icon}`}):_vm._e(),(item.iconSvg)?_c('div',{staticClass:"submenu-item-icon",domProps:{"innerHTML":_vm._s(item.iconSvg)}}):_vm._e(),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.title))])]),(item.tag)?_c('b-badge',{staticClass:"hp-text-color-black-100 hp-bg-success-3 rounded-pill px-8 border-0 ml-32",attrs:{"variant":"none"}},[_vm._v(" "+_vm._s(item.tag)+" ")]):_vm._e()],1):_c('span',[(item.icon)?_c('i',{class:`iconly-${item.icon}`}):_vm._e(),(item.iconSvg)?_c('div',{staticClass:"submenu-item-icon",domProps:{"innerHTML":_vm._s(item.iconSvg)}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.title))])])]),(item.children)?_c('ul',{staticClass:"dropdown-menu"},_vm._l((item.children),function(item,index){return _c('li',{key:index,staticClass:"dropend"},[(item.children)?_c('b-link',{class:`dropdown-item${
                _vm.path.split('/')[3] == item.id ? ' active' : ''
              }`},[_c('span',[_vm._v(_vm._s(item.title))]),_c('i',{staticClass:"dropdown-menu-arrow"})]):(item.target)?_c('b-link',{class:`${_vm.path == item.navLink ? ' active' : ''}`,attrs:{"href":item.navLink,"target":item.target}},[_c('span',[_vm._v(_vm._s(item.title))])]):_c('b-link',{class:`${_vm.path == item.navLink ? ' active' : ''}`,attrs:{"to":item.navLink}},[_c('span',[_vm._v(_vm._s(item.title))])]),(item.children)?_c('ul',{staticClass:"dropdown-menu"},_vm._l((item.children),function(item,index){return _c('li',{key:index},[_c('b-link',{class:`${_vm.path == item.navLink ? ' active' : ''}`,attrs:{"to":item.navLink}},[_c('span',[_vm._v(_vm._s(item.title))])])],1)}),0):_vm._e()],1)}),0):_vm._e()],1)}),0):_vm._e()]):_vm._e()}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }